import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { config } from "../../config";

export const AdminKYCReview = () => {
  const [users, setUsers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [noMoreUsers, setNoMoreUsers] = useState(false);
  const [score, setScore] = useState("A");

  const [idFrontImg, setIdFrontImg] = useState("");
  const [idBackImg, setIdBackImg] = useState("");
  const [selfieImg, setSelfieImg] = useState("");

  const fetchPendingUsers = async () => {
    setIsLoading(true);
    setError("");

    try {
      const response = await fetch(
        `${config.backendURL}/admin/users-by-kyc-status?status=pending`,
        {
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (!data.users) {
          setError("No se encontraron usuarios con KYC pendiente");
          return;
        }
        setUsers(data.users);
        if (data.users.length === 0) {
          setNoMoreUsers(true);
        }
      } else {
        setError("Error al buscar usuarios con KYC pendiente");
      }
    } catch (err) {
      setError("Ocurrió un error al buscar usuarios");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchImage = async (type, email) => {
    try {
      const response = await fetch(
        `${config.backendURL}/admin/kyc?type=${type}&email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } else {
        throw new Error("Failed to load image");
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return "";
    }
  };

  const updateUserKYCStatus = async (email, status) => {
    setIsLoading(true);

    const response = await fetch(`${config.backendURL}/admin/kyc`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${new Cookies().get("token")}`,
      },
      body: JSON.stringify({ email, status, score }),
    });

    if (response.ok) {
      if (currentIndex + 1 < users.length) {
        setCurrentIndex(currentIndex + 1);
        setIdFrontImg("");
        setIdBackImg("");
        setSelfieImg("");
      } else {
        setNoMoreUsers(true);
      }
    } else {
      setError("Error al actualizar el estado de KYC");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  useEffect(() => {
    const loadImages = async (email) => {
      const idFrontUrl = await fetchImage("id_front", email);
      const idBackUrl = await fetchImage("id_back", email);
      const selfieUrl = await fetchImage("selfie", email);

      setIdFrontImg(idFrontUrl);
      setIdBackImg(idBackUrl);
      setSelfieImg(selfieUrl);
    };

    if (users.length > 0 && currentIndex < users.length) {
      const currentUser = users[currentIndex];
      loadImages(currentUser.email);
    }
  }, [currentIndex, users]);

  const currentUser = users[currentIndex];

  if (isLoading) return <div>Cargando...</div>;
  if (error) return <div className={"error"}>{error}</div>;
  if (noMoreUsers)
    return (
      <div>
        No hay más usuarios para revisar su KYC. Por favor, recargar la página
        para chequear usuarios nuevos o que fueron salteados.
      </div>
    );

  return (
    <div className={"container"}>
      <h1>Revisión de KYC</h1>
      {currentUser && (
        <div>
          <p>Email: {currentUser.email}</p>
          <p>Nombre: {currentUser.name}</p>
          <p>Apellido: {currentUser.surname}</p>
          <p>
            Celular: +{currentUser.country_cellphone_code}{" "}
            {currentUser.cellphone}
          </p>
          <p>CUIT: {currentUser.cuit}</p>
          {currentUser.business_address && (
            <p>Nombre de Empresa: {currentUser.business_name}</p>
          )}

          {currentUser.business_address && (
            <p>ID de Empresa: {currentUser.business_id}</p>
          )}

          <p>
            Estado del KYC:{" "}
            {currentUser.kyc_status === "pending" ? "Pendiente" : "Rechazado"}
          </p>
          <img src={idFrontImg} className="kyc-img" alt="Frente del ID" />
          <img src={idBackImg} className="kyc-img" alt="Dorso del ID" />
          <img src={selfieImg} className="kyc-img" alt="Selfie" />
          <div className="score-div">
            <p>Seleccione un Score:</p>
            <label className="score-label" htmlFor="kyc-status">
              <select
                className="score-select"
                onChange={(e) => setScore(e.target.value)}
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
              </select>
            </label>
          </div>

          <button
            onClick={() => updateUserKYCStatus(currentUser.email, "approved")}
          >
            Aprobar
          </button>
          <button
            onClick={() => updateUserKYCStatus(currentUser.email, "rejected")}
          >
            Rechazar
          </button>
          <button onClick={() => setCurrentIndex(currentIndex + 1)}>
            Saltar
          </button>
        </div>
      )}
    </div>
  );
};
