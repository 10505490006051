import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Spinner, Alert, Button, Card, Col, Row } from "react-bootstrap";
import { config } from "../../config";
import "bootstrap/dist/css/bootstrap.min.css";
export const AdminNewChecks = () => {
  const [pendingChecks, setPendingChecks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

 

  useEffect(() => {
    fetchPendingChecks();
  }, []);

  const fetchPendingChecks = async () => {
    setIsLoading(true);
    setError("");

    try {
      const cookies = new Cookies();
      const token = cookies.get("token");

      const response = await fetch(`${config.backendURL}/admin/pending-checks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setPendingChecks(data.pending_checks || []);
      } else {
        setError("Error al obtener los cheques pendientes");
      }
    } catch (err) {
      setError("Ocurrió un error al obtener datos");
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (checkId) => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    console.log(checkId);
  
    try {
      const response = await fetch(`${config.backendURL}/admin/check`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: checkId, status: "active" }),
      });
  
      console.log(`Response status: ${response.status}`);
  
      if (response.ok) {
        fetchPendingChecks(); // Actualiza la lista de cheques pendientes
      } else {
        const errorText = await response.text();
        console.error(`Error response: ${errorText}`);
        setError("Error al aprobar el cheque");
      }
    } catch (err) {
      console.error(`Catch error: ${err}`);
      setError("Ocurrió un error al aprobar el cheque");
    }
  };
  
  

  const handleReject = async (checkId) => {
    const cookies = new Cookies();
    const token = cookies.get("token");

    try {
      const response = await fetch(`${config.backendURL}/admin/check`, {
        method: "PUT",
        headers: {
        "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: checkId, status: "cancelled" }),
      });

      if (response.ok) {
        fetchPendingChecks(); // Actualiza la lista de cheques pendientes
      } else {
        setError("Error al rechazar el cheque");
      }
    } catch (err) {
      setError("Ocurrió un error al rechazar el cheque");
    }
  };

  return (
    <div className="container mt-5">
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Cargando...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : pendingChecks.length === 0 ? (
        <Alert variant="info">No hay cheques pendientes para validar</Alert>
      ):(
        <Row>
            
          {pendingChecks.map((check) => (
            <Col md={6} key={check.id}>
              <Card className="mb-3">
                <Card.Header>
                  <div className="d-flex justify-content-between">
                    <span>
                      {check.type === "e-check" ? "Cheque Electrónico" : "Cheque Físico"}
                    </span>
                    <span>#{check.number}</span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div className="check-details">
                      <span className="detail-label">
                        Importe de cheque: <span className="not-bold">{check.amount}</span>
                      </span>
                      <br />
                      <span className="detail-label">
                        Vencimiento: <span className="not-bold">{new Date(check.expiration_date * 1000).toLocaleDateString()}</span>
                      </span>
                      <br />
                      <span className="detail-label">
                        Días para el vencimiento:{" "}
                        <span className="not-bold">
                          {Math.ceil((check.expiration_date * 1000 - Date.now()) / (1000 * 60 * 60 * 24)) - 1}
                        </span>
                      </span>
                      <br/>
                      <span className="detail-label">
                        Monto Solicitado: <span className="not-bold">{check.desired_amount}</span>
                      </span>
                      <br />
                      <span className="detail-label">
                        Razón Social: <span className="not-bold">{check.social_reason}</span>
                      </span>
                      <br />
                      <span className="detail-label">
                        CUIT: <span className="not-bold">{check.cuit}</span>
                      </span>
                      <br />
                      <span className="detail-label">
                        CUIT del Librador: <span className="not-bold">{check.cuit_librador}</span>
                      </span>
                    </div>
                  </Card.Text>
                  <div className="d-flex justify-content-between">
                    <Button
                      variant="success"
                      onClick={() => handleApprove(check.id)}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleReject(check.id)}
                    >
                      Rechazar
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};
