import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { config } from "../../config";

export const AdminAllUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleScoreChange = async (e, email, currentValue) => {
    const confirmed = window.confirm("¿Estás seguro de cambiar el score?");
    if (!confirmed) {
      e.target.value = currentValue;
      return;
    }

    try {
      const token = new Cookies().get("token");
      const response = await fetch(`${config.backendURL}/admin/update-score`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email,
          score: e.target.value,
        }),
      });

      if (response.ok) {
        alert("Score actualizado correctamente");
      } else {
        alert("Error al actualizar el score");
        e.target.value = currentValue;
      }
    } catch (err) {
      alert("Error al actualizar el score");
      e.target.value = currentValue;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = new Cookies().get("token");
        const response = await fetch(`${config.backendURL}/admin/all-users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log("data:", data);
          if (data.users) {
            setUsers(data.users);
            console.log("users:", data.users);
          } else {
            setError("No se encontraron resultados");
          }
        } else {
          setError("Error al buscar datos de usuarios");
          setUsers([]);
        }
      } catch (err) {
        setError("Ocurrió un error al buscar datos de usuarios");
        setUsers([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className={"container"}>
      <h1 className="simple-title">Lista de Usuarios</h1>

      {isLoading && <div>Cargando...</div>}
      {error && <div className={"error"}>{error}</div>}
      {users.length > 0 && (
        <table className={"table"}>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Celular</th>
              <th>CUIT</th>
              <th>Nombre de Empresa</th>
              <th>ID de Empresa</th>
              <th>Estado del KYC</th>
              <th>Score</th>
              <th>Es Admin</th>
              <th>Numero de Transacciones</th>
              <th>% Comisiones</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.surname}</td>
                <td>{user.email}</td>
                <td>
                  +{user.country_cellphone_code} {user.cellphone}
                </td>
                <td>{user.cuit}</td>
                <td>{user.business_name || "-"}</td>
                <td>{user.business_id || "-"}</td>
                <td>
                  {user.kyc_status === "approved"
                    ? "Aprobado"
                    : user.kyc_status === "pending"
                    ? "Pendiente"
                    : "Rechazado"}
                </td>
                <td>
                  {" "}
                  <select
                    className="status-dropdown"
                    value={user.score}
                    onChange={(e) =>
                      handleScoreChange(e, user.email, user.score)
                    }
                  >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                  </select>
                </td>
                <td>{user.admin ? "Sí" : "No"}</td>
                <td>{user.number_of_transactions}</td>
                <td>{user.fee ? user.fee + "%" : "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
