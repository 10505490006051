import React, { useState } from "react";
import Cookies from "universal-cookie";
import { config } from "../../config";

export const AdminCheckDetails = () => {
  const [check, setCheck] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [checkId, setCheckId] = useState("");

  const handleDeleteCheck = async (check) => {
    if (check.status !== "active") {
      alert("No se puede eliminar un cheque que no está activo");
      return;
    }

    try {
      const response = await fetch(`${config.backendURL}/delete-check`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
        body: JSON.stringify({ id: check.id }),
      });

      if (response.ok) {
        alert("Cheque eliminado correctamente");
        window.location.reload();
      } else {
        alert("Error al eliminar cheque");
      }
    } catch (err) {
      alert("Error al eliminar cheque:", err);
    }
  };

  const fetchCheckData = async () => {
    setIsLoading(true);
    setError("");

    const cookies = new Cookies();
    const token = cookies.get("token");
    if (!token) {
      window.location.href = "/login";
    }

    fetch(`${config.backendURL}/profile`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.user.admin) {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        alert("Error obteniendo datos del usuario", error);
      });

    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      const response = await fetch(
        `${config.backendURL}/admin/check?id=${checkId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCheck(data.check);
      } else {
        setError("Failed to fetch check data");
        setCheck(null);
      }
    } catch (err) {
      setError("An error occurred while fetching check data");
      setCheck(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchCheckData();
  };

  return (
    <div className={"container"}>
      <h1 className="simple-title">Detalles del Cheque</h1>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={checkId}
          onChange={(e) => setCheckId(e.target.value)}
          className="input-text-style"
          placeholder="Ingresar ID o número del Cheque"
        />
        <button className="buscar-btn" type="submit">
          Buscar
        </button>
      </form>
      {isLoading && <div>Cargando...</div>}
      {error && <div className={"error"}>{error}</div>}
      {check && (
        <table className={"table"}>
          <thead>
            <tr>
              <th>Banco</th>
              <th>Tipo</th>
              <th>Numero de Cheque</th>
              <th>Monto</th>
              <th>Fecha de emisión</th>
              <th>Fecha de expiración</th>
              <th>Razón Social</th>
              <th>CUIT</th>
              <th>Monto Deseado</th>
              <th>Subido por</th>
              <th>Fecha subida</th>
              <th>Expirado</th>
              <th>Num. celular vendedor</th>
              <th>Estado</th>
              <th>Borrar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{check.bank}</td>
              <td>
                {check.type === "physical-check" ? "Físico" : "Electrónico"}
              </td>
              <td>{check.number}</td>
              <td>${check.amount}</td>
              <td>
                {new Date(check.emition_date * 1000).toLocaleDateString(
                  "en-GB"
                )}
              </td>
              <td>
                {new Date(check.expiration_date * 1000).toLocaleDateString(
                  "en-GB"
                )}
              </td>
              <td>{check.social_reason}</td>
              <td>{check.cuit}</td>
              <td>${check.desired_amount}</td>
              <td>{check.uploaded_by}</td>
              <td>{new Date(check.uploaded_at).toLocaleString()}</td>
              <td>{check.expired ? "Si" : "No"}</td>
              <td>
                +{check.country_cellphone_code} {check.cellphone}
              </td>
              <td>
                {check.status === "active"
                  ? "Activo"
                  : check.status === "sold"
                  ? "Vendido"
                  : check.status === "cancelled"
                  ? "Cancelado"
                  : "Expirado"}
              </td>
              <td className="table-data" data-label="Estado">
                <button onClick={async () => await handleDeleteCheck(check)}>
                  Borrar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
