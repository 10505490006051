import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Spinner, Alert, Button, Card, Col, Row, Form } from "react-bootstrap";
import { config } from "../../config";
import "bootstrap/dist/css/bootstrap.min.css";

const FeeConfigTable = () => {
  const [feeConfigs, setFeeConfigs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState("");

  useEffect(() => {
    fetchFeeConfigs();
  }, []);

  const fetchFeeConfigs = async () => {
    setIsLoading(true);
    setError("");

    try {
      const cookies = new Cookies();
      const token = cookies.get("token");

      const response = await fetch(`${config.backendURL}/fee-configs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFeeConfigs(data || []);
      } else {
        setError("Error al obtener los FeeConfigs");
      }
    } catch (err) {
      setError("Ocurrió un error al obtener datos");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (index, currentValue) => {
    setEditingIndex(index);
    setEditingValue(currentValue);
  };

  const handleSaveClick = async (index, level) => {
    const cookies = new Cookies();
    const token = cookies.get("token");

    try {
      const response = await fetch(`${config.backendURL}/fee-configs`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ level, fee: parseFloat(editingValue) }),
      });

      if (response.ok) {
        setEditingIndex(null);
        fetchFeeConfigs(); // Actualiza la lista de FeeConfigs
      } else {
        setError("Error al actualizar el FeeConfig");
      }
    } catch (err) {
      setError("Ocurrió un error al actualizar el FeeConfig");
    }
  };

  return (
    <div className="container mt-5">
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Cargando...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : feeConfigs.length === 0 ? (
        <Alert variant="info">No hay FeeConfigs disponibles</Alert>
      ) : (
        <Row>
          {feeConfigs.map((config, index) => (
            <Col md={6} key={config.ID}>
              <Card className="mb-3">
                <Card.Header>
                  <div className="d-flex justify-content-between">
                    <span>Nivel: {config.Level}</span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="fee-details">
                    <span className="detail-label">
                      Fee:{" "}
                      {editingIndex === index ? (
                        <Form.Control
                          type="number"
                          value={editingValue}
                          onChange={(e) => setEditingValue(e.target.value)}
                        />
                      ) : (
                        config.Fee
                      )}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    {editingIndex === index ? (
                      <Button
                        variant="success"
                        onClick={() => handleSaveClick(index, config.Level)}
                      >
                        Guardar
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => handleEditClick(index, config.Fee)}
                      >
                        Editar
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default FeeConfigTable;
