import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { LoadingComponent } from "./loading_component";
import { config } from "../config";
import { AlertProvider, useAlert } from './alert.service';
import { Modal, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

export const CheckList = () => {
  const [checks, setChecks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // Usuario seleccionado
  const [showModal, setShowModal] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null); // Estado para cheque seleccionado
  const [offer, setOffer] = useState(null); // Estado para oferta
  const [showOfferModal, setShowOfferModal] = useState(false); // Estado para mostrar modal de oferta
  const { addAlert } = useAlert();

  const limit = 10; // You can adjust this limit as needed

  const numberFormatter = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const percentageFormatter = new Intl.NumberFormat("es-AR", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError("");

      try {
        const token = new Cookies().get("token");
        const response = await fetch(
          `${config.backendURL}/checks?status=active&page=${currentPage}&limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          if (!data.checks || data.checks.length === 0) {
            setIsLoading(false);
            setError("No se encontraron cheques");
            return;
          }
          setIsLoading(false);
          setHasNextPage(data.has_next_page);
          setChecks(data.checks);
        } else {
          if (data.error) {
            setError("Error al obtener cheques: " + data.error);
          } else {
            setError("Error al obtener cheques");
            console.log(response);
          }
        }
      } catch (err) {
        setError("Error al obtener cheques: " + err);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [currentPage]); // Fetch data when currentPage changes

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUserDetails = async (email) => {
    try {
      const token = new Cookies().get("token");
      const response = await fetch(`${config.backendURL}/users/email/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      console.log(data.user)
      if (response.ok) {
        setSelectedUser(data.user);
        setShowModal(true);
      } else {
        addAlert("error", data.error || "Error al obtener detalles del usuario");
      }
    } catch (err) {
      addAlert("error", "Error al obtener detalles del usuario: " + err.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleOpenOfferModal = (check) => {
    setSelectedCheck(check);
    setShowOfferModal(true);
  };

  const handleCloseOfferModal = () => {
    setShowOfferModal(false);
    setSelectedCheck(null);
    setOffer(null);
  };

  if (isLoading) return <LoadingComponent />;
  if (error) return <div className={"error"}>{error}</div>;

  return (
    <div className={"container"}>
      <h1>Cotizaciones</h1>
      <div className={"check-cards"}>
        {checks.map((check) => (
          <div key={check.id} className={"check-card"}>
            <div className={"check-card-header"}>
              <span className={"check-type"}>
                {check.type === "e-check"
                  ? "Cheque Electrónico"
                  : "Cheque Físico"}
              </span>
              <span className={"check-username"}>#{check.number}</span>
            </div>
            <div className={"check-card-body"}>
              <div className={"check-details"}>
                <span className="detail-label">
                  Importe de cheque:{" "}
                  <span className="not-bold">
                    {numberFormatter.format(check.amount)}
                  </span>
                </span>
                <span className="detail-label">
                  Vencimiento:{" "}
                  <span className="not-bold">
                    {new Date(check.expiration_date * 1000).toLocaleDateString(
                      "en-GB"
                    )}
                  </span>
                </span>
                <span className="detail-label">
                  Dias para el vencimiento:{" "}
                  <span className="not-bold">
                    {Math.ceil(
                      (check.expiration_date * 1000 - Date.now()) /
                        (1000 * 60 * 60 * 24)
                    )}
                  </span>
                </span>
                <span className="detail-label">
                  Monto Solicitado:{" "}
                  <span className="not-bold">
                    ${check.desired_amount_with_fee}
                  </span>
                </span>
                <span>
                  <span className="detail-label">
                    Margen Neto:{" "}
                    <span className="not-bold">
                      {numberFormatter.format(
                        roundUp(check.amount - check.desired_amount_with_fee)
                      )}
                    </span>
                  </span>
                </span>
                <span className="detail-label">
                  TNA:{" "}
                  <span className="not-bold">
                    {percentageFormatter.format(
                      calcularTNA(
                        check.amount,
                        check.desired_amount_with_fee,
                        check.expiration_date
                      ) / 100
                    )}
                  </span>
                </span>
              </div>
              <div className={"check-card-actions"}>
                <button
                  onClick={() => {
                    handleUserDetails(check.uploaded_by);
                  }}
                  className={"details-button"}
                >
                  Datos
                </button>
                <button
                  onClick={() => {
                    handleOpenOfferModal(check);
                  }}
                  className={"details-button"}
                >
                  Ofertar
                </button>
                <button
                  className={"buy-button"}
                  onClick={() => {
                    const cookies = new Cookies();
                    const token = cookies.get("token");
                    fetch(`${config.backendURL}/transaction`, {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer " + token,
                      },
                      body: JSON.stringify({
                        check_id: check.id,
                      }),
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        if (data.error) {
                          if (data.error.includes("KYC")) {
                            addAlert('error', 'Debe completar el KYC para comprar cheques.');
                          } else {
                            addAlert('error', data.error);
                          }
                        } else {
                          const wpp_link = data.wpp_link;
                          setTimeout(() => {
                            window.location.href = wpp_link;
                          });
                        }
                      })
                      .catch((error) => {
                        setError("Error al comprar cheque: " + error);
                      });
                  }}
                >
                  Comprar
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={"pagination"}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previa
        </button>
        <span>Página {currentPage}</span>
        <button
          disabled={!hasNextPage}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Siguiente
        </button>
      </div>
      {selectedUser && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton className="text-center">
            <Modal.Title className="w-100">Datos del Vendedor</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>Nombre: {selectedUser.name || 0}</p>
            <p>Cheques Creados: {selectedUser.DataCheck?.CreatedChecks || 0}</p>
            <p>Cheques Cancelados: {selectedUser.DataCheck?.CanceledChecks || 0}</p>
            <p>Cheques Vendidos: {selectedUser.DataCheck?.SoldChecks || 0}</p>
            <p>Score: {selectedUser.score || 0}</p>
            <p>Monto Total Vendido: $ {selectedUser.DataCheck?.TotalSoldAmount || 0}</p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="primary" onClick={handleCloseModal}>
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {selectedCheck && (
        <CheckOfferModal
          show={showOfferModal}
          onHide={handleCloseOfferModal}
          check={selectedCheck}
        />
      )}
    </div>
  );
};

function roundUp(num) {
  return Math.ceil(num * 100) / 100;
}

function calcularTNA(importeCheque, montoPagado, timestampVencimiento) {
  console.log(
    "importeCheque:",
    importeCheque,
    "montoPagado:",
    montoPagado,
    "timestampVencimiento:",
    timestampVencimiento
  );
  let fechaVencimiento = new Date(timestampVencimiento * 1000);
  let fechaActual = new Date();
  let diferenciaTiempo = fechaVencimiento - fechaActual;
  let diasHastaVencimiento = diferenciaTiempo / (1000 * 3600 * 24);

  // Asegurarse de que los días hasta el vencimiento no sean negativos y redondear a un valor entero
  diasHastaVencimiento = Math.max(Math.ceil(diasHastaVencimiento), 0);

  console.log("Días hasta vencimiento: " + diasHastaVencimiento);
  let ganancia = importeCheque - montoPagado;
  console.log("ganancia:", ganancia);
  let tna = (ganancia / montoPagado) * (360 / diasHastaVencimiento);
  return tna * 100;
}

const CheckOfferModal = ({ show, onHide, check }) => {
  const [offer, setOffer] = useState(null);
  console.log("offer:", offer);
  const { addAlert } = useAlert();

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Ofertar por el cheque Nº {check.number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="check-details">
          <div className="check-detail">
            <span className="detail-label">
              Precio solicitado por el vendedor:
            </span>
            <span className="detail-value">
              ${check.desired_amount_with_fee}
            </span>
          </div>
          <div className="check-detail">
            <span className="detail-label">Oferta:</span>
            <input
              type="number"
              step="0.01"
              name="desired_amount"
              onWheel={(e) => e.target.blur()} // Blur the input field when scrolling to prevent changes
              min="0" // Ensures the value cannot be negative
              className="formInput"
              onKeyDown={(e) => {
                // Prevent "e", "-", "+", and "." characters in the input
                if (e.key === "e" || e.key === "-" || e.key === "+") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                if (e.target.value === "") {
                  setOffer(null);
                  return;
                }
                setOffer(Number(e.target.value));
                console.log("setting", e.target.value);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (!offer || offer === 0 || isNaN(offer)) {
              alert("Ingrese un monto válido para ofertar.");
              return;
            }
            const cookies = new Cookies();
            fetch(`${config.backendURL}/offer`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.get("token"),
              },
              body: JSON.stringify({
                check_id: check.id,
                offer_amount: offer,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  addAlert("error", "Error al enviar oferta: " );
                } else {
                  addAlert("success", "Oferta enviada con éxito. Te notificaremos por email si el vendedor la acepta." );
                  onHide();
                }
              })
              .catch((error) => {
                addAlert("error", "Error al enviar oferta: " + error);
              });
          }}
        >
          Enviar oferta
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
