import React, { useEffect, useState } from "react";
import { LoadingComponent } from "./loading_component";
import { config } from "../config";

export const OfferStatus = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    setStatus(status);
    const offerID = urlParams.get("offer_id");

    if (!offerID || !status) {
      alert(
        "Parámetros inválidos. Si el problema persiste, contacte a soporte."
      );
      return;
    }

    fetch(`${config.backendURL}/offer-status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        offer_id: offerID,
        status,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error.includes("aceptada o rechazada")) {
            alert(data.error);
          } else {
            alert(
              "Hubo un error al procesar su oferta. Si el problema persiste, contacte a soporte."
            );
          }
          return;
        }
        setIsLoading(false);
      })
      .catch((error) => {
        alert(
          "Hubo un error al procesar su oferta. Si el problema persiste, contacte a soporte."
        );
        console.error("Error:", error);
      });
  }, []);
  return (
    <>
      <div className="center-div" style={{ height: "95vh" }}>
        <div className="form-container">
          {isLoading ? (
            <LoadingComponent></LoadingComponent>
          ) : status === "accepted" ? (
            <>
              <h1 style={{ textAlign: "center" }}>
                La oferta fue aprobada con éxito! 🎉
              </h1>
              <p>
                Vamos a ponernos en contacto con el comprador para informarle y
                que pueda realizar el pago.
              </p>
            </>
          ) : (
            <>
              <h1 style={{ textAlign: "center" }}>
                La oferta fue rechazada con éxito! ❌
              </h1>
              <p>
                Lamentamos que no hayas quedado satisfecho con la oferta. Nos
                comunicaremos con el comprador en la brevedad.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
