// src/components/AlertComponent.js
import React from 'react';

const AlertComponent = ({ type, message, onClose }) => {
  const alertType = type === 'success' ? 'alert-success' : 'alert-danger';

  const alertStyle = type === 'error' ? { backgroundColor: '#ff4d4f', color: '#fff' } : {};

  return (
    <div className={`alert ${alertType} alert-dismissible fade show`} role="alert" style={alertStyle}>
      {message}
      <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
    </div>
  );
};

export default AlertComponent;
