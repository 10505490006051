import React, { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import { config } from "../config";
export const LoginForm = () => {
  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("token")) {
      window.location.href = "/profile";
    }
  }, []);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.backendURL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) {
        // Assuming a 401 or similar error on invalid credentials
        throw new Error("Credenciales Inválidas");
      }
      const data = await response.json();
      // ... (handle setting cookies and any other success behavior)
      console.log(data);

      const cookies = new Cookies();
      cookies.set("token", data.jwt, { path: "/", maxAge: 3600 * 24 });

      if (data.kyc_required) {
        window.location.href = "/kyc";
      } else {
        window.location.href = "/profile";
      }
    } catch (error) {
      console.error("Error sending data", error);
      setErrorMessage("Credenciales Inválidas"); // Update the error message
    }
  };

  return (
    <>
      <div className="center-div form-container-div" style={{ height: "95vh" }}>
        <form onSubmit={handleSubmit} className="form-container">
          <h1 style={{ textAlign: "center" }}>Ingresá a Plaza Cheque</h1>
          <input
            className="input-field"
            type="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            placeholder="Email"
            onInvalid={(e) => {
              e.target.setCustomValidity("Email inválido");
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
            }}
          />

          <div
            className="input-wrapper"
            style={{ width: "100%", paddingRight: "25px" }}
          >
            <input
              className="input-field"
              type={showPassword ? "text" : "password"}
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Contraseña"
            />
            <div
              className="toggle-password-visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? (
                <svg className="eye-icon" viewBox="0 0 60 50">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <g>
                          {" "}
                          <path d="M29.008,48.308c-16.476,0-28.336-17.029-28.833-17.754c-0.248-0.36-0.231-0.841,0.039-1.184 c0.561-0.712,13.906-17.424,29.913-17.424c17.953,0,29.474,16.769,29.956,17.482c0.23,0.342,0.229,0.79-0.007,1.129 c-0.475,0.688-11.842,16.818-29.899,17.721C29.786,48.297,29.396,48.308,29.008,48.308z M2.267,30.028 c2.326,3.098,13.553,16.967,27.812,16.254c15.237-0.76,25.762-13.453,27.938-16.3c-2.175-2.912-12.811-16.035-27.889-16.035 C16.7,13.947,4.771,27.084,2.267,30.028z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <path d="M30.127,37.114c-3.852,0-6.986-3.135-6.986-6.986c0-3.851,3.134-6.985,6.986-6.985s6.986,3.135,6.986,6.985 C37.113,33.979,33.979,37.114,30.127,37.114z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <path d="M30.127,42.614c-6.885,0-12.486-5.602-12.486-12.486c0-6.883,5.602-12.485,12.486-12.485 c6.884,0,12.486,5.602,12.486,12.485C42.613,37.012,37.013,42.614,30.127,42.614z M30.127,19.641 c-5.782,0-10.486,4.704-10.486,10.486c0,5.781,4.704,10.485,10.486,10.485s10.486-4.704,10.486-10.485 C40.613,24.345,35.91,19.641,30.127,19.641z"></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              ) : (
                <svg
                  className="eye-icon"
                  viewBox="0 0 25 20"
                  style={{ height: "20px", width: "20px" }}
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M20 14.8335C21.3082 13.3317 22 12 22 12C22 12 18.3636 5 12 5C11.6588 5 11.3254 5.02013 11 5.05822C10.6578 5.09828 10.3244 5.15822 10 5.23552M12 9C12.3506 9 12.6872 9.06015 13 9.17071C13.8524 9.47199 14.528 10.1476 14.8293 11C14.9398 11.3128 15 11.6494 15 12M3 3L21 21M12 15C11.6494 15 11.3128 14.9398 11 14.8293C10.1476 14.528 9.47198 13.8524 9.1707 13C9.11386 12.8392 9.07034 12.6721 9.04147 12.5M4.14701 9C3.83877 9.34451 3.56234 9.68241 3.31864 10C2.45286 11.1282 2 12 2 12C2 12 5.63636 19 12 19C12.3412 19 12.6746 18.9799 13 18.9418"
                      stroke="#000000"
                      strokeWidth="1.5"
                      stroke-linecap="round"
                      strokeLinejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              )}
            </div>
          </div>
          {errorMessage && (
            <div className="error-message">{"Error: " + errorMessage}</div>
          )}
          <div className="center-div">
            <p>
              {" "}
              <a href="/recover">Olvidé mi contraseña</a>
            </p>
            <button className="submit-button" type="submit">
              Loguearse
            </button>
            <p>
              {" "}
              No estás registrado? <a href="/signup">Registrate!</a>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};
