import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { LoadingComponent } from "./loading_component";
import "../styles/form.css"; // Ensure the path is correct for your CSS
import "../styles/profile.css"; // Ensure the path is correct for your CSS
import { config } from "../config";

export const NewProfileComponent = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const [profileData, setProfileData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [checks, setChecks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [transactionsLoaded, setTransactionsLoaded] = useState(false);
  const [checksLoaded, setChecksLoaded] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEditingSociety, setIsEditingSociety] = useState(false);
  const [societyFormData, setSocietyFormData] = useState({
    society: false,
    business_name: "",
    business_id: "",
  });

  const updatePassword = async () => {
    setIsUpdating(true);
    const cookies = new Cookies();
    const token = cookies.get("token");

    if (newPassword !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }

    try {
      const response = await fetch(`${config.backendURL}/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          new_password: newPassword,
          password_confirmation: confirmPassword,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Contraseña actualizada.");
        setIsEditingPassword(false);
        setNewPassword("");
      } else {
        if (data.error) {
          alert("Error actualizando contraseña: " + data.error);
          return;
        }
        console.error("Error updating password:", data.message);
        alert("Error actualizando contraseña");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Error actualizando contraseña");
    } finally {
      setIsUpdating(false);
    }
  };

  const updateSocietyData = async () => {
    setIsUpdating(true);
    const cookies = new Cookies();
    const token = cookies.get("token");

    try {
      const response = await fetch(`${config.backendURL}/profile/society`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: societyFormData.email,
          society: societyFormData.society,
          business_name: societyFormData.business_name,
          business_id: societyFormData.business_id,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Datos de la sociedad actualizados.");
        setIsEditingSociety(false);
        fetchProfileData(); // Refresh profile data
      } else {
        if (data.error) {
          alert("Sociedad actualizada " + data.error);
          return;
        }
        console.error("Error updating society data:", data.message);
        alert("Error actualizando datos de la sociedad");
      }
    } catch (error) {
      console.error("Error updating society data:", error);
      alert("Error actualizando datos de la sociedad");
    } finally {
      setIsUpdating(false);
    }
  };

  const cookies = new Cookies();
  const token = cookies.get("token");

  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.backendURL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setProfileData(data.user);
        setSocietyFormData({
          society: data.user.society,
          business_name: data.user.business_name || "",
          business_id: data.user.business_id || "",
        });
      } else {
        setError("Error fetching profile data");
      }
    } catch (err) {
      console.log("Err:", err);
      setError("Error fetching profile data: " + err.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (transactionsLoaded) return;
      
      setIsLoading(true);
      setError("");

      try {
        const response = await fetch(`${config.backendURL}/transactions`, {
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`, // Include token in Authorization header
          },
        });
        const data = await response.json();
        if (response.ok) {
          if (data.transactions) {
            setTransactions(data.transactions);
            setTransactionsLoaded(true);
          }
        } else {
          if (data.error) {
            setError("Error al obtener transacciones: " + data.error);
          } else {
            setError("Error al obtener transacciones");
          }
        }
      } catch (err) {
        setError("Error al obtener transacciones:", err);
      }

      setIsLoading(false);
    };

    const fetchChecks = async () => {
      if (checksLoaded) return;

      setIsLoading(true);
      setError("");

      try {
        const response = await fetch(`${config.backendURL}/checks/user`, {
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          if (data.checks) {
            setChecks(data.checks);
            setChecksLoaded(true);
          }
        } else {
          if (data.error) {
            setError("Error al obtener cheques: " + data.error);
          } else {
            setError("Error al obtener cheques");
          }
        }
      } catch (err) {
        setError("Error al obtener cheques:", err);
      }

      setIsLoading(false);
    };

    if (activeTab === "transactions") {
      fetchTransactions();
    } else if (activeTab === "checks") {
      fetchChecks();
    }
  }, [activeTab, checksLoaded, token, transactionsLoaded]); // Dependency array includes activeTab and token

  const handleTabClick = (tabName) => {
    setError(""); // Reset errors when changing tabs
    setActiveTab(tabName);
  };

  const handleDeleteCheck = async (check) => {
    if (check.status !== "active") {
      alert("No se puede eliminar un cheque que no está activo");
      return;
    }

    try {
      const response = await fetch(`${config.backendURL}/delete-check`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
        body: JSON.stringify({ id: check.id }),
      });

      if (response.ok) {
        alert("Cheque eliminado correctamente");
      } else {
        alert("Error al eliminar cheque");
      }
    } catch (err) {
      alert("Error al eliminar cheque:", err);
    }
  };

  const renderTransactionTable = () => {
    return (
      <div className="table-responsive">
        <table className="transactions-table">
          <thead>
            <tr className="transactions-tr">
              <th className="table-header">ID del Cheque</th>
              <th className="table-header">Email Comprador</th>
              <th className="table-header">Estado</th>
              <th className="table-header">Comisión</th>
              <th className="table-header">Fecha de la Oferta</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.check_id}>
                <td className="table-data">{transaction.check_id}</td>
                <td className="table-data">{transaction.buyer_email}</td>
                <td className="table-data">
                  {transaction.status === "pending"
                    ? "Pendiente"
                    : transaction.status === "complete"
                    ? "Completada"
                    : "Cancelada"}
                </td>
                <td className="table-data">{transaction.fee}</td>
                <td className="table-data">
                  {new Date(transaction.created_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderChecksTable = () => {
    return (
      <div className="table-responsive">
        <table className="checks-table">
          <thead>
            <tr>
              <th className="table-header">Banco</th>
              <th className="table-header">Tipo</th>
              <th className="table-header">Número de Cheque</th>
              <th className="table-header">Monto</th>
              <th className="table-header">Fecha de emisión</th>
              <th className="table-header">Fecha de expiración</th>
              <th className="table-header">Razón Social</th>
              <th className="table-header">CUIT</th>
              <th className="table-header">Monto Deseado</th>
              <th className="table-header">Fecha Subida</th>
              <th className="table-header">Estado</th>
              <th className="table-header">Eliminar Cheque</th>
            </tr>
          </thead>
          <tbody>
            {checks.map((check) => (
              <tr key={check.id}>
                <td className="table-data" data-label="Banco">
                  {check.bank}
                </td>
                <td className="table-data" data-label="Tipo">
                  {check.type === "e-check"
                    ? "Cheque Electrónico"
                    : "Cheque Físico"}
                </td>
                <td className="table-data" data-label="Número de Cheque">
                  {check.number}
                </td>
                <td className="table-data" data-label="Monto">
                  {check.amount}
                </td>
                <td className="table-data" data-label="Fecha de emisión">
                  {new Date(check.emition_date * 1000).toLocaleDateString(
                    "en-GB"
                  )}
                </td>
                <td className="table-data" data-label="Fecha de expiración">
                  {new Date(check.expiration_date * 1000).toLocaleDateString(
                    "en-GB"
                  )}
                </td>
                <td className="table-data" data-label="Razón Social">
                  {check.social_reason}
                </td>
                <td className="table-data" data-label="CUIT">
                  {check.cuit}
                </td>
                <td className="table-data" data-label="Monto Deseado">
                  {check.desired_amount}
                </td>
                <td className="table-data" data-label="Fecha Subida">
                  {new Date(check.uploaded_at).toLocaleString()}
                </td>
                <td className="table-data" data-label="Estado">
                  {check.status === "active"
                    ? "Activo"
                    : check.status === "cancelled"
                    ? "Cancelado"
                    : check.status === "expired"
                    ? "Expirado"
                    : "Vendido"}
                </td>
                <td className="table-data" data-label="Estado">
                  <button onClick={async () => await handleDeleteCheck(check)}>
                    Borrar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (isLoading) return <LoadingComponent />;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="container">
      <div className="profile-tabs">
        <p
          className={`section-tab ${
            activeTab === "profile" ? "tab-active" : ""
          }`}
          onClick={() => handleTabClick("profile")}
        >
          Perfil
        </p>
        <p
          className={`section-tab ${
            activeTab === "transactions" ? "tab-active" : ""
          }`}
          onClick={() => handleTabClick("transactions")}
        >
          Mis Transacciones
        </p>
        <p
          className={`section-tab ${
            activeTab === "checks" ? "tab-active" : ""
          }`}
          onClick={() => handleTabClick("checks")}
        >
          Mis Cheques
        </p>
      </div>

      {activeTab === "profile" && profileData && (
        <div className="profile-container">
          <div className="profile-section">
            <h2 className="section-title">Datos de ingreso</h2>
            <div className="profile-item">
              <span className="profile-label">E-mail:</span>
              <span className="profile-value">{profileData.email}</span>
            </div>

            <div className="profile-item">
              <span className="profile-label">Contraseña:</span>
              {isEditingPassword ? (
                <>
                  <input
                    type="password"
                    placeholder="Nueva contraseña"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="new-password-input"
                  />
                  <input
                    type="password"
                    placeholder="Confirmar contraseña"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="confirm-password-input"
                  />
                </>
              ) : (
                <span>********</span>
              )}
              {isEditingPassword ? (
                <button
                  className="save-button"
                  onClick={updatePassword}
                  disabled={isUpdating}
                >
                  Guardar
                </button>
              ) : (
                <button
                  className="change-button"
                  onClick={() => setIsEditingPassword(true)}
                >
                  Cambiar
                </button>
              )}
            </div>
          </div>

          <div className="profile-section">
            <h2 className="section-title">Datos personales</h2>
            <div className="profile-item">
              <span className="profile-label">Nombre:</span>
              <span className="profile-value">{profileData.name}</span>
            </div>
            <div className="profile-item">
              <span className="profile-label">Apellido:</span>
              <span className="profile-value">{profileData.surname}</span>
            </div>
            <div className="profile-item">
              <span className="profile-label">Teléfono:</span>
              <span className="profile-value">
                +{profileData.country_cellphone_code} {profileData.cellphone}
              </span>
            </div>
            <div className="profile-item">
              <span className="profile-label">Documento:</span>
              <span className="profile-value">{profileData.cuit}</span>
            </div>
            <div className="profile-item">
              <span className="profile-label">Estado del KYC:</span>
              <span className="profile-value">
                {profileData.kyc_status === "approved"
                  ? "Aprobado"
                  : profileData.kyc_status === "rejected"
                  ? "Rechazado"
                  : profileData.kyc_status === "pending"
                  ? "Pendiente"
                  : "No enviado"}
              </span>
            </div>
            <div>
              {profileData.society ? (
                <>
                  <div className="profile-item">
                    <span className="profile-label">Razón Social:</span>
                    <span className="profile-value">
                      {profileData.business_name}
                    </span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">CUIT Empresa:</span>
                    <span className="profile-value">
                      {profileData.business_id}
                    </span>
                  </div>
                </>
              ) : (
                <div className="profile-item">
                  <span className="profile-label">Sociedad:</span>
                  <span className="profile-value">
                    Tu perfil no representa una Sociedad
                  </span>
                  <div>
                    <button
                      className="change-button"
                      onClick={() => setIsEditingSociety(true)}
                    >
                      Representar una Sociedad
                    </button>
                  </div>
                </div>
              )}
            </div>
            {isEditingSociety && (
              <div className="profile-item">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    name="society"
                    checked={societyFormData.society}
                    onChange={(e) =>
                      setSocietyFormData({
                        ...societyFormData,
                        society: e.target.checked,
                      })
                    }
                  />
                  Represento a una Sociedad
                </label>
                {societyFormData.society && (
                  <>
                    <input
                      className="input-field"
                      name="business_name"
                      value={societyFormData.business_name}
                      onChange={(e) =>
                        setSocietyFormData({
                          ...societyFormData,
                          business_name: e.target.value,
                          email:profileData.email
                        })
                      }
                      placeholder="Razón Social"
                    />
                    <input
                      className="input-field"
                      name="business_id"
                      type="text"
                      value={societyFormData.business_id}
                      onChange={(e) =>
                        setSocietyFormData({
                          ...societyFormData,
                          business_id: e.target.value,
                        })
                      }
                      placeholder="CUIT de la Sociedad"
                      pattern="\d*"
                      title="El CUIT de la Sociedad debe contener solo números"
                    />
                  </>
                )}
                <button
                  className="save-button"
                  onClick={updateSocietyData}
                  disabled={isUpdating}
                >
                  Guardar
                </button>
              </div>
            )}
            <div className="profile-item">
              <span className="profile-label">Comisiones:</span>
              <span className="profile-value"> {profileData.fee} %</span>
            </div>
            <button className="logout-button" onClick={logout}>
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}
      {activeTab === "transactions" && renderTransactionTable()}
      {activeTab === "checks" && renderChecksTable()}
    </div>
  );
};

const logout = () => {
  const cookies = new Cookies();
  cookies.remove("token");

  window.location.href = "/login";
};
