import React, { useState } from "react";
import Cookies from "universal-cookie";
import { config } from "../../config";

export const AdminUserSearch = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("");

  const fetchUserData = async () => {
    setIsLoading(true);
    setError("");
    setUsers([]);

    try {
      const token = new Cookies().get("token");
      const response = await fetch(
        `${config.backendURL}/admin/users?filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("data:", data);
        if (data.users) {
          setUsers(data.users);
        } else {
          setError("No se encontraron resultados");
        }
      } else {
        setError("Error al buscar datos de usuarios");
        setUsers([]);
      }
    } catch (err) {
      setError("Ocurrió un error al buscar datos de usuarios");
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchUserData();
  };

  return (
    <div className={"container"}>
      <h1 className="simple-title">Buscar Usuarios</h1>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={filter}
          className="input-text-style"
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Ingresar filtro de búsqueda"
        />
        <button className="buscar-btn" type="submit">
          Buscar
        </button>
      </form>
      {isLoading && <div>Cargando...</div>}
      {error && <div className={"error"}>{error}</div>}
      {users.length > 0 && (
        <table className={"table"}>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Celular</th>
              <th>CUIT</th>
              <th>Nombre de Empresa</th>
              <th>ID de Empresa</th>
              <th>Estado del KYC</th>
              <th>Score</th>
              <th>Es Admin</th>
              <th>Numero de Transacciones</th>
              <th>% Comisiones</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.surname}</td>
                <td>{user.email}</td>
                <td>
                  +{user.country_cellphone_code} {user.cellphone}
                </td>
                <td>{user.cuit}</td>
                <td>{user.business_name || "-"}</td>
                <td>{user.business_id || "-"}</td>
                <td>
                  {user.kyc_status === "approved"
                    ? "Aprobado"
                    : user.kyc_status === "pending"
                    ? "Pendiente"
                    : "Rechazado"}
                </td>
                <td>{user.score}</td>
                <td>{user.admin ? "Sí" : "No"}</td>
                <td>{user.number_of_transactions}</td>
                <td>{user.fee ? user.fee + "%" : "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
