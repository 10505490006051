import { useEffect } from "react";
import Cookies from "universal-cookie";
import { config } from "../config";

export const Admin = () => {
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    if (token) {
      fetch(`${config.backendURL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.user.admin) {
            window.location.href = "/";
          }
        })
        .catch((error) => {
          console.error("Error fetching data", error);
          // Handle error
        });
    }
  }, []);
  return (
    <div className="admin-container">
      <h1 className="admin-title">Panel Administrador</h1>
      <div className="admin-buttons">
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/transactions";
          }}
        >
          Ver Transacciones
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/check";
          }}
        >
          Buscar Cheque
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/users";
          }}
        >
          Buscar Usuario
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/all-users";
          }}
        >
          Lista de Usuarios
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/kyc";
          }}
        >
          Administrar KYCs
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/transactions-stats";
          }}
        >
          Estadistica de Transacciones
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/users-stats";
          }}
        >
          Estadistica de Usuarios
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/new-checks";
          }}
        >
          Ver Cheques Pendientes
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/fee-config";
          }}
        >
          Editar Fee / TNA
        </button>
        <button
          className="admin-button"
          onClick={() => {
            window.location.href = "/admin/transaction-by-id";
          }}
        >
          Buscar Transacción por ID
        </button>
      </div>
    </div>
  );
};
