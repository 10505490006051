import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { LoadingComponent } from "./loading_component";
import { config } from "../config";
import { sendMessage } from "./send_msj_twilio";  
import { Modal, Button } from 'react-bootstrap';
import { AlertProvider, useAlert } from './alert.service';

const banks = [
  "BANCO DE GALICIA Y BUENOS AIRES S.A.U.",
  "BANCO DE LA NACION ARGENTINA",
  "BANCO DE LA PROVINCIA DE BUENOS AIRES",
  "INDUSTRIAL AND COMMERCIAL BANK OF CHINA",
  "CITIBANK N.A.",
  "BANCO BBVA ARGENTINA S.A.",
  "BANCO DE LA PROVINCIA DE CORDOBA S.A.",
  "BANCO SUPERVIELLE S.A.",
  "BANCO DE LA CIUDAD DE BUENOS AIRES",
  "BANCO PATAGONIA S.A.",
  "BANCO HIPOTECARIO S.A.",
  "BANCO DE SAN JUAN S.A.",
  "BANCO MUNICIPAL DE ROSARIO",
  "BANCO SANTANDER RIO S.A.",
  "BANCO DEL CHUBUT S.A.",
  "BANCO DE SANTA CRUZ S.A.",
  "BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M",
  "BANCO DE CORRIENTES S.A.",
  "BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ",
  "BANK OF CHINA LIMITED SUCURSAL BUENOS AI",
  "BRUBANK S.A.U.",
  "BANCO INTERFINANZAS S.A.",
  "HSBC BANK ARGENTINA S.A.",
  "OPEN BANK ARGENTINA S.A.",
  "JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO",
  "BANCO CREDICOOP COOPERATIVO LIMITADO",
  "BANCO DE VALORES S.A.",
  "BANCO ROELA S.A.",
  "BANCO MARIVA S.A.",
  "BANCO ITAU ARGENTINA S.A.",
  "BNP PARIBAS",
  "BANCO PROVINCIA DE TIERRA DEL FUEGO",
  "BANCO DE LA REPUBLICA ORIENTAL DEL URUGU",
  "BANCO SAENZ S.A.",
  "BANCO MERIDIAN S.A.",
  "BANCO MACRO S.A.",
  "BANCO COMAFI SOCIEDAD ANONIMA",
  "BANCO DE INVERSION Y COMERCIO EXTERIOR S",
  "BANCO PIANO S.A.",
  "BANCO JULIO SOCIEDAD ANONIMA",
  "BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL",
  "BANCO DEL SOL S.A.",
  "NUEVO BANCO DEL CHACO S. A.",
  "BANCO VOII S.A.",
  "BANCO DE FORMOSA S.A.",
  "BANCO CMF S.A.",
  "BANCO DE SANTIAGO DEL ESTERO S.A.",
  "BANCO INDUSTRIAL S.A.",
  "NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA",
  "BANCO CETELEM ARGENTINA S.A.",
  "BANCO DE SERVICIOS FINANCIEROS S.A.",
  "BANCO BRADESCO ARGENTINA S.A.U.",
  "BANCO DE SERVICIOS Y TRANSACCIONES S.A.",
  "RCI BANQUE S.A.",
  "BACS BANCO DE CREDITO Y SECURITIZACION S",
  "BANCO MASVENTAS S.A.",
  "WILOBANK S.A.U.",
  "NUEVO BANCO DE ENTRE RÍOS S.A.",
  "BANCO COLUMBIA S.A.",
  "BANCO BICA S.A.",
  "BANCO COINAG S.A.",
  "BANCO DE COMERCIO S.A.",
  "BANCO SUCREDITO REGIONAL S.A.U.",
  "BANCO DINO S.A.",
  "FORD CREDIT COMPAÑIA FINANCIERA S.A.",
  "COMPAÑIA FINANCIERA ARGENTINA S.A.",
  "VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F",
  "IUDU COMPAÑÍA FINANCIERA S.A.",
  "FCA COMPAÑIA FINANCIERA S.A.",
  "GPAT COMPAÑIA FINANCIERA S.A.U.",
  "MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT",
  "ROMBO COMPAÑÍA FINANCIERA S.A.",
  "JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.",
  "PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER",
  "TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA",
  "NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A.",
  "MONTEMAR COMPAÑIA FINANCIERA S.A.",
  "TRANSATLANTICA COMPAÑIA FINANCIERA S.A.",
  "CREDITO REGIONAL COMPAÑIA FINANCIERA S.A",
];
const checkTypes = ["e-check", "physical-check"];

export const CheckForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // Estado para controlar la visibilidad del modal
  const [wppLink, setWppLink] = useState(""); // Estado para almacenar el enlace de redirección
  const { addAlert } = useAlert();
  const validateForm = () => {
    // ... lógica de validación del formulario ...
  };

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");

    if (!token) {
      window.location.href = "/login"; // Redirect to login if no token
      return;
    }
    const handleShowSuccessAlert = () => {
      addAlert('success', 'This is a success alert!');
    };
  
    const handleShowErrorAlert = () => {
      addAlert('error', 'This is an error alert!');
    };
    // Get user data
    fetch(`${config.backendURL}/profile`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user.kyc_status === "pending") {
          addAlert('error', 'Su KYC está pendiente de aprobación. Por favor, espere para vender su cheque.');
          return;
        }

        if (data.user.kyc_status === "not_submitted") {
          addAlert('error', 'Aún no enviaste tu KYC. Esto es obligatorio para vender su cheque.');
          return;
        }

        if (data.user.kyc_status === "rejected") {
          addAlert('error', 'Su KYC fue rechazado. Por favor, envíe su KYC nuevamente para vender su cheque.');
          return;
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [formData, setFormData] = useState({
    bank: banks[0],
    type: "e-check",
    number: "",
    amount: "",
    emition_date: "",
    expiration_date: "",
    social_reason: "",
    cuit: "",
    cuit_librador: "",
    desired_amount: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,
      amount: Number(formData.amount),
      number: Number(formData.number),
      cuit: Number(formData.cuit),
      cuit_librador: Number(formData.cuit_librador),
      desired_amount: Number(formData.desired_amount),
      emition_date: new Date(formData.emition_date).getTime() / 1000,
      expiration_date: new Date(formData.expiration_date).getTime() / 1000,
    };

    setErrorMessage("");

    const error = validateForm();
    if (error) {
      console.log("error:", error);
      setErrorMessage(error);
      return;
    }

    try {
      const response = await fetch(`${config.backendURL}/check`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + new Cookies().get("token"),
        },
        body: JSON.stringify(dataToSend),
      });

      const responseData = await response.json();
      if (response.ok) {
        await sendMessage('Nuevo cheque creado! Ingresa para aprobarlo!');
        setWppLink(responseData.wpp_link); // Almacenar el enlace de redirección
        setShowModal(true); // Mostrar el modal
      } else {
        if (responseData.error) {
          if (responseData.error.toString().includes("duplicate")) {
            setErrorMessage("El número de cheque ya existe.");
            return;
          }
          setErrorMessage(responseData.error);
        } else {
          alert("Error al subir el cheque");
        }
      }
    } catch (error) {
      console.error("Error sending the form:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.href = wppLink; // Redirigir al enlace cuando se cierra el modal
  };

  return (
    <div className={"center-div"}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <h1 className={"formTitle"}>Publicar Cheque</h1>
          <form onSubmit={handleSubmit} className={"formContainer"}>
            {/* Formulario con los campos necesarios */}
            <label className={"formLabel"}>
              Banco:
              <select
                name="bank"
                value={formData.bank}
                onChange={handleInputChange}
                className={"formSelect"}
              >
                {banks.map((bank, index) => (
                  <option key={index} value={bank}>
                    {bank}
                  </option>
                ))}
              </select>
            </label>
            <label className={"formLabel"}>
              Tipo:
              <select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                className={"formSelect"}
              >
                {checkTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type === "e-check"
                      ? "Cheque Electrónico"
                      : "Cheque Físico"}
                  </option>
                ))}
              </select>
            </label>
            <label className="formLabel">
              Numero de Cheque:
              <input
                type="number"
                name="number"
                value={formData.number}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "-" ||
                    e.key === "+" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                }}
                min="0"
                className="formInput"
              />
            </label>
            <label className="formLabel">
              Importe (solo números y comas):
              <input
                type="number"
                step="0.01"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                onWheel={(e) => e.currentTarget.blur()}
                min="0"
                className="formInput"
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "-" ||
                    e.key === "+" ||
                    e.key === "." ||
                    e.key === "$"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </label>
            <label className={"formLabel"}>
              Fecha de Emisión:
              <input
                type="date"
                name="emition_date"
                value={formData.emition_date}
                onChange={handleInputChange}
                max={new Date().toISOString().split("T")[0]}
                className="formInput"
              />
            </label>
            <label className={"formLabel"}>
              Fecha de Expiración:
              <input
                type="date"
                name="expiration_date"
                value={formData.expiration_date}
                onChange={handleInputChange}
                min={
                  new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .split("T")[0]
                }
                className="formInput"
              />
            </label>
            <label className={"formLabel"}>
              Razón Social:
              <input
                type="text"
                name="social_reason"
                value={formData.social_reason}
                onChange={handleInputChange}
                className={"formInput"}
              />
            </label>
            <label className="formLabel">
              CUIT:
              <input
                type="text"
                name="cuit"
                value={formData.cuit}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleInputChange(e);
                  }
                }}
                pattern="\d*"
                placeholder="Ingrese CUIT sin guiones"
                className="formInput"
              />
            </label>
            <label className="formLabel">
              CUIT del Librador:
              <input
                type="text"
                name="cuit_librador"
                value={formData.cuit_librador}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleInputChange(e);
                  }
                }}
                pattern="\d*"
                placeholder="Ingrese CUIT del Librador sin guiones"
                className="formInput"
              />
            </label>
            <label className="formLabel">
              Monto Deseado (solo números y comas):
              <input
                type="number"
                step="0.01"
                name="desired_amount"
                value={formData.desired_amount}
                onChange={handleInputChange}
                onWheel={(e) => e.target.blur()}
                min="0"
                className="formInput"
                onKeyDown={(e) => {
                  if (e.key === "e" || e.key === "-" || e.key === "+") {
                    e.preventDefault();
                  }
                }}
              />
            </label>
            {errorMessage && (
              <div className={"error-message"}>{errorMessage}</div>
            )}
            <button type="submit" className={"submitButton"}>
              PUBLICAR CHEQUE
            </button>
          </form>
        </>
      )}
    <Modal show={showModal} onHide={handleCloseModal} centered>
  <Modal.Header closeButton className="text-center">
    <Modal.Title className="w-100">Aviso</Modal.Title>
  </Modal.Header>
  <Modal.Body className="text-center">
    <p>Cheque creado, en estado de aprobación por un administrador</p>
  </Modal.Body>
  <Modal.Footer className="d-flex justify-content-center">
    <Button variant="primary" onClick={handleCloseModal}>
      Aceptar
    </Button>
  </Modal.Footer>
</Modal>
    </div>
  );
};