import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import { config } from "../config";

export const FileUploadForm = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    if (!token) {
      window.location.href = "/login";
      return;
    }

    fetch(`${config.backendURL}/profile`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Profile data:", data);
        if (data.user.kyc_status === "approved" || data.user.kyc_status === "pending") {
          console.log("User already has a KYC approved or pending");
          window.location.href = "/";
        } else {
          setShowContent(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, []);

  const [files, setFiles] = useState({
    selfie: null,
    id_front: null,
    id_back: null,
  });

  const handleFileChange = (e) => {
    setFiles({
      ...files,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!files.selfie || !files.id_front || !files.id_back) {
      alert("All files are required!");
      return;
    }

    const formData = new FormData();
    formData.append("selfie", files.selfie);
    formData.append("id_front", files.id_front);
    formData.append("id_back", files.id_back);

    try {
      const cookies = new Cookies();
      const response = await fetch(`${config.backendURL}/kyc`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error uploading files:", errorData);
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log("KYC submission response:", data);
      alert("KYC enviado correctamente");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error sending data:", error);
      alert("Error enviando KYC:", error);
    }
  };

  return (
    showContent && (
      <div className="file-upload-container">
        <h1>Envianos tu KYC</h1>
        <h4>
          Necesitamos que nos envíes una foto de tu DNI (tanto de atrás como de
          adelante) y una selfie tuya para corroborar tus datos. De esta forma,
          vas a poder operar con normalidad en Plaza Cheque.
        </h4>
        <p>Los formatos admitidos son: jpg, jpeg y png.</p>
        <form onSubmit={handleSubmit}>
          <div className="file-input-group">
            <label className="inputfile-label" htmlFor="selfie">
              {files.selfie ? files.selfie.name : "Selfie con tu DNI en Mano"}
            </label>
            <input
              className="inputfile"
              type="file"
              id="selfie"
              name="selfie"
              accept=".jpg,.jpeg,.png"
              required
              onChange={handleFileChange}
              onInvalid={(e) => {
                e.target.setCustomValidity("Archivo Inválido o no seleccionado");
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
              }}
            />
          </div>
          <div className="file-input-group">
            <label className="inputfile-label" htmlFor="id_front">
              {files.id_front ? files.id_front.name : "Foto de la parte delantera de tu DNI"}
            </label>
            <input
              className="inputfile"
              type="file"
              id="id_front"
              name="id_front"
              accept=".jpg,.jpeg,.png"
              required
              onChange={handleFileChange}
              onInvalid={(e) => {
                e.target.setCustomValidity("Archivo Inválido o no seleccionado");
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
              }}
            />
          </div>
          <div className="file-input-group">
            <label className="inputfile-label" htmlFor="id_back">
              {files.id_back ? files.id_back.name : "Foto de la parte trasera de tu DNI"}
            </label>
            <input
              id="id_back"
              className="inputfile"
              type="file"
              name="id_back"
              accept=".jpg,.jpeg,.png"
              required
              onChange={handleFileChange}
              onInvalid={(e) => {
                e.target.setCustomValidity("Archivo Inválido o no seleccionado");
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
              }}
            />
          </div>
          <button className="submit-button" type="submit">
            ENVIAR
          </button>
        </form>
      </div>
    )
  );
};
