import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { LoadingComponent } from "../loading_component";
import { config } from "../../config";

export const AdminTransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const limit = 10; // Adjust the limit as needed
  const [status, setStatus] = useState("pending");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError("");

      const cookies = new Cookies();
      const token = cookies.get("token");
      if (!token) {
        window.location.href = "/login";
      }

      fetch(`${config.backendURL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.user.admin) {
            window.location.href = "/";
          }
        })
        .catch((error) => {
          alert("Error obteniendo datos del usuario", error);
        });

      try {
        const cookies = new Cookies();
        const token = cookies.get("token");

        const response = await fetch(
          `${config.backendURL}/admin/transactions-with-status?status=${status}&page=${currentPage}&limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include token in Authorization header
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (!data.transactions || data.transactions.length === 0) {
            setTransactions([]);
            setHasNextPage(false);
          } else {
            setTransactions(data.transactions);
            setHasNextPage(data.has_next_page);
          }
        } else {
          setError("Failed to fetch data from server");
        }
      } catch (err) {
        setIsLoading(false);
        setError("An error occurred while fetching data");
      }

      setIsLoading(false);
    };

    fetchData();
  }, [currentPage, status]); // Fetch data when currentPage or status changes

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (isLoading) return <LoadingComponent></LoadingComponent>;
  if (error) return <div className={"error"}>{error}</div>;

  return (
    <div className="admin-container">
      <h1 className="admin-title">TRANSACCIONES</h1>
      <div>
        <select
          className="status-dropdown"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="pending">Pendientes</option>
          <option value="complete">Completadas</option>
          <option value="cancelled">Canceladas</option>
        </select>
      </div>
      <table className="transaction-table">
        <thead className="transaction-table-header">
          <tr className="transaction-table-row">
            <th className="transaction-table-cell">ID Transacción</th>
            <th className="transaction-table-cell">ID Cheque</th>
            <th className="transaction-table-cell">Email Vendedor</th>
            <th className="transaction-table-cell">Teléfono Vendedor</th>
            <th className="transaction-table-cell">Email Comprador</th>
            <th className="transaction-table-cell">Teléfono Comprador</th>
            <th className="transaction-table-cell">Estado</th>
            <th className="transaction-table-cell">Comisión</th>
            <th className="transaction-table-cell">Fecha Creación</th>
            <th className="transaction-table-cell">Fecha Modificación</th>
            <th className="transaction-table-cell">Monto Transacción</th>
            <th className="transaction-table-cell">
              Monto Transacción con Comisión
            </th>
            <th className="transaction-table-cell">
              Oferta Realizada? (Sí/No)
            </th>
            <th className="transaction-table-cell">
              Monto de la Oferta (a pagar por el comprador)
            </th>
            <th className="transaction-table-cell">
              Monto a recibir por el vendedor (solo ofertas)
            </th>
            <th className="transaction-table-cell">Número de Cheque</th>
            <th className="transaction-table-cell">Valor del Cheque</th>
            <th className="transaction-table-cell">Fecha Expiración</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id} className="transaction-table-row">
              <td className="transaction-table-cell">{transaction.id}</td>
              <td className="transaction-table-cell">{transaction.check_id}</td>
              <td className="transaction-table-cell">
                {transaction.uploaded_by}
              </td>
              <td className="transaction-table-cell">
                +{transaction.uploader_country_code}{" "}
                {transaction.uploader_cellphone}
              </td>
              <td className="transaction-table-cell">
                {transaction.buyer_email}
              </td>
              <td className="transaction-table-cell">
                +{transaction.buyer_country_code} {transaction.buyer_cellphone}
              </td>
              <td className="transaction-table-cell">
                {transaction.status === "pending"
                  ? "Pendiente"
                  : transaction.status === "complete"
                  ? "Completada"
                  : "Cancelada"}
              </td>
              <td className="transaction-table-cell">{transaction.fee}%</td>
              <td className="transaction-table-cell">
                {new Date(transaction.created_at).toLocaleString()}
              </td>
              <td className="transaction-table-cell">
                {transaction.updated_at === "0001-01-01T00:00:00Z"
                  ? "-"
                  : new Date(transaction.updated_at).toLocaleString()}
              </td>
              <td className="transaction-table-cell">
                ${transaction.desired_amount.toLocaleString()}
              </td>
              <td className="transaction-table-cell">
                $
                {transaction.desired_amount +
                  (transaction.desired_amount * transaction.fee) / 100}
              </td>
              <td className="transaction-table-cell">
                {transaction.offer_id !== "000000000000000000000000"
                  ? "Sí"
                  : "No"}
              </td>
              <td className="transaction-table-cell">
                {transaction.offer_price
                  ? `$${transaction.offer_price.toLocaleString()}`
                  : "-"}
              </td>
              <td className="transaction-table-cell">
                $
                {transaction.offer_price
                  ? transaction.offer_price -
                    (transaction.offer_price * transaction.fee) / 100
                  : "-"}
              </td>
              <td className="transaction-table-cell">
                {transaction.check_number}
              </td>
              <td className="transaction-table-cell">
                ${transaction.check_amount.toLocaleString()}
              </td>
              <td className="transaction-table-cell">
                {new Date(
                  transaction.expiration_date * 1000
                ).toLocaleDateString("en-GB")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-container">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">Page {currentPage}</span>
        <button
          className={`pagination-button ${
            !hasNextPage ? "pagination-button-disabled" : ""
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!hasNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};
