import React, { useEffect, useState } from "react";
import "./styles/form.css";
import "./styles/header.css";
import logo from "./assets/logo.png";
import Cookies from "universal-cookie";
import { AlertProvider } from './components/alert.service';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { CheckForm } from "./components/sell_check";
import { LoginForm } from "./components/login";
import { Signup } from "./components/signup";
import { FileUploadForm } from "./components/file_upload";
import { CheckList } from "./components/get_checks";
import { Admin } from "./components/admin";
import { AdminTransactionList } from "./components/admin/transactions";
import { AdminCheckDetails } from "./components/admin/check_details";
import { AdminUserSearch } from "./components/admin/users";
import { AdminKYCReview } from "./components/admin/kyc_check";
import { AdminTransactionsStats } from "./components/admin/transactions_stats";
import { AdminUserStats } from "./components/admin/users_stats";
import { AdminTransactionDetails } from "./components/admin/transaction_by_id";
import { NewProfileComponent } from "./components/new_profile";
import { OfferStatus } from "./components/offer";
import { config } from "./config";
import {
  CheckRecoverPassword,
  RecoverPasswordForm,
} from "./components/recover_password";
import { AdminAllUsers } from "./components/admin/all-users";
import { AdminNewChecks } from "./components/admin/new-checks";
import FeeConfigTable from "./components/admin/fee-config";
const Home = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [token, setToken] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [KYCDone, setKYCDone] = useState(false);

  const closeNav = () => {
    setIsNavExpanded(false);
  };

  const RedirectBasedOnToken = () => {
    const tokenExists = token !== "";
    return tokenExists ? <Navigate to="/profile" /> : <Navigate to="/login" />;
  };

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    if (token) {
      fetch(`${config.backendURL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setIsAdmin(data.user.admin);
          if (
            data.user.kyc_status === "approved" ||
            data.user.kyc_status === "pending"
          ) {
            setKYCDone(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data", error);
          // Handle error
        });
    }
    setToken(token);
  }, []);

  return (
    <Router>
      <div className="home-container">
        <header className="header">
          {/* Asegúrate de que la ruta del logo sea correcta */}
          <a href="https://plazacheque.com.ar/">
            <img src={logo} alt="Logo" className="logo" />
          </a>

          <button
            className={`hamburger ${isNavExpanded ? "active" : ""}`}
            onClick={() => setIsNavExpanded(!isNavExpanded)}
          >
            &#9776;
          </button>

          <nav className={isNavExpanded ? "navigation expanded" : "navigation"}>
            <ul>
              {!token ? (
                <>
                  <li>
                    <Link to="/signup" onClick={closeNav}>
                      Registrarse
                    </Link>
                  </li>
                  <li>
                    <Link to="/login" onClick={closeNav}>
                      Ingresar
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  {KYCDone ? null : (
                    <li>
                      <Link to="/kyc" onClick={closeNav}>
                        KYC
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link to="/profile" onClick={closeNav}>
                      Mi Perfil
                    </Link>
                  </li>
                  <li>
                    <Link to="/sell" onClick={closeNav}>
                      Vender Cheque
                    </Link>
                  </li>
                  <li>
                    <Link to="/checks" onClick={closeNav}>
                      Cotizaciones
                    </Link>
                  </li>
                  {isAdmin && (
                    <li>
                      <Link to="/admin" onClick={closeNav}>
                        Panel Administrador
                      </Link>
                    </li>
                  )}
                </>
              )}
            </ul>
          </nav>
        </header>
        <AlertProvider>
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/recover" element={<RecoverPasswordForm />} />
          <Route path="/recover-password" element={<CheckRecoverPassword />} />
          <Route path="/offer" element={<OfferStatus />} />
          <Route path="/kyc" element={<FileUploadForm />} />
          <Route path="/profile" element={<NewProfileComponent />} />
          <Route path="/sell" element={<CheckForm />} />
          <Route path="/checks" element={<CheckList />} />
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/admin/transactions"
            element={<AdminTransactionList />}
          />
          <Route path="/admin/check" element={<AdminCheckDetails />} />
          <Route path="/admin/new-checks" element={<AdminNewChecks />} />
          <Route path="/admin/fee-config" element={<FeeConfigTable />} />
          <Route path="/admin/users" element={<AdminUserSearch />} />
          <Route path="/admin/all-users" element={<AdminAllUsers />} />
          <Route path="/admin/kyc" element={<AdminKYCReview />} />
          <Route
            path="/admin/transactions-stats"
            element={<AdminTransactionsStats />}
          />
          <Route path="/admin/users-stats" element={<AdminUserStats />} />
          <Route
            path="/admin/transaction-by-id"
            element={<AdminTransactionDetails />}
          />

          {/* Tus otras rutas aquí */}
          <Route path="*" element={<RedirectBasedOnToken />} />
        </Routes>
        </AlertProvider>

      </div>
    </Router>
  );
};

// const LoginForm = () => {
//   const [credentials, setCredentials] = useState({ email: "", password: "" });
//   const [errorMessage, setErrorMessage] = useState(""); // New state for error message

//   const handleChange = (e) => {
//     setCredentials({
//       ...credentials,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("http://localhost:8080/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(credentials),
//       });
//       if (!response.ok) {
//         // Assuming a 401 or similar error on invalid credentials
//         throw new Error("Invalid credentials");
//       }
//       const data = await response.json();
//       // ... (handle setting cookies and any other success behavior)
//       console.log(data);

//       const cookies = new Cookies();
//       cookies.set("token", data.jwt, { path: "/", maxAge: 3600 * 24 });
//     } catch (error) {
//       console.error("Error sending data", error);
//       setErrorMessage("invalid credentials"); // Update the error message
//     }
//   };

//   return (
//     <>
//       <form onSubmit={handleSubmit} className="form-container">
//         <h1
//           style={{
//             textAlign: "center",
//             color: "#fff7ed",
//             marginBottom: "50px",
//             marginTop: "40px",
//           }}
//         >
//           Inicia Sesión
//         </h1>

//         <label htmlFor="email">Correo electrónico</label>
//         <input
//           className="input-field"
//           id="email"
//           type="email"
//           name="email"
//           value={credentials.email}
//           onChange={handleChange}
//           placeholder="Correo electrónico"
//         />

//         <label htmlFor="password">Contraseña</label>
//         <input
//           className="input-field"
//           id="password"
//           type="password"
//           name="password"
//           value={credentials.password}
//           onChange={handleChange}
//           placeholder="Ingrese su contraseña"
//         />

//         {errorMessage && <div className="error-message">{errorMessage}</div>}

//         <button className="submit-button" type="submit">
//           Ingresar
//         </button>
//       </form>
//     </>
//   );
// };

export default Home;
