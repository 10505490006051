// sendMessage.js
const accountSid = 'ACd73b3a85dfbc32e756daa001c96bb268';
const authToken = '5237b368f3d6d385c44bb308454af8c0';
const twilioNumber = 'whatsapp:+14155238886';
const recipientNumber = 'whatsapp:+14155238886';

export const sendMessage = async (message) => {
  const response = await fetch(`https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Basic ${btoa(`${accountSid}:${authToken}`)}`,
    },
    body: new URLSearchParams({
      Body: message,
      From: twilioNumber,
      To: recipientNumber,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    console.log("Message sent successfully! SID: " + data.sid);
  } else {
    console.error("Failed to send message.");
  }
};
