import React, { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import { config } from "../config";
export const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    if (token) {
      window.location.href = "/";
    }
  }, []);

  // Initialize state for form data
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    country_cellphone_code: "",
    cellphone: "",
    email: "",
    cuit: "",
    password: "",
    password_confirmation: "",
    society: false,
    business_name: "",
    business_id: "",
  });

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.name || formData.name.length > 40) {
      isValid = false;
      newErrors.name =
        "El nombre es obligatorio y debe tener menos de 40 caracteres";
    }

    if (!formData.surname || formData.surname.length > 40) {
      isValid = false;
      newErrors.surname =
        "El apellido es obligatorio y debe tener menos de 40 caracteres";
    }

    if (
      !formData.country_cellphone_code ||
      formData.country_cellphone_code.length > 5
    ) {
      isValid = false;
      newErrors.country_cellphone_code =
        "El código de teléfono del país es obligatorio y debe tener menos de 5 caracteres";
    }

    // Simple regex de email para validación básica - ajustar según sea necesario
    const emailRegex = /\S+@\S+\.\S+/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      isValid = false;
      newErrors.email = "Se requiere un email válido";
    }

    if (
      !formData.password ||
      formData.password.length > 32 ||
      formData.password.length < 6
    ) {
      isValid = false;
      newErrors.password =
        "La contraseña es obligatoria y debe tener menos de 32 caracteres y más de 5 caracteres";
    }

    if (formData.password !== formData.password_confirmation) {
      isValid = false;
      newErrors.password_confirmation = "Las contraseñas deben coincidir";
    }

    if (!formData.cuit) {
      isValid = false;
      newErrors.cuit = "El CUIT es obligatorio";
    }

    if (formData.society) {
      if (!formData.business_name || formData.business_name.length > 70) {
        isValid = false;
        newErrors.business_name =
          "El nombre comercial es obligatorio y debe tener menos de 70 caracteres cuando se marca la sociedad";
      }

      if (!formData.business_id) {
        isValid = false;
        newErrors.business_id =
          "El ID comercial es obligatorio cuando se marca la sociedad";
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  // Update state on input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // boolean
      });
    } else {
      // Permitir solo valores numéricos para campos específicos
      if (
        name === "cuit" ||
        name === "business_id" ||
        name === "cellphone" ||
        name === "country_cellphone_code"
      ) {
        const numericValue = value.replace(/\D/g, ""); // Elimina todo lo que no sea un dígito
        setFormData({
          ...formData,
          [name]: numericValue,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Validation Failed");
      return; // Stop submission if validation fails
    }
    const dataToSend = {
      ...formData,
      // Convierte cuit y business_id a enteros si no están vacíos, de lo contrario asigna un valor predeterminado o maneja como mejor te parezca
      cuit: formData.cuit ? parseInt(formData.cuit, 10) : 0, // Asegúrate de manejar los casos en que el campo esté vacío o tenga solo ceros
      business_id: formData.business_id
        ? parseInt(formData.business_id, 10)
        : 0,
      country_cellphone_code: formData.country_cellphone_code
        ? parseInt(formData.country_cellphone_code, 10)
        : 0,
      cellphone: formData.cellphone ? parseInt(formData.cellphone, 10) : 0,
    };
    try {
      const response = await fetch(`${config.backendURL}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      const data = await response.json();

      if (!response.ok) {
        if (data.error.toString().includes("email")) {
          console.log("error email!!");
          setErrors({
            email: "El email ingresado ya se encuentra registrado.",
          });
        } else if (data.error.toString().includes("cuit")) {
          setErrors({ cuit: "Usuario con ese CUIT ya existe" });
        } else if (data.error.toString().includes("cellphone")) {
          setErrors({
            cellphone: "El número de celular ya se encuentra registrado",
          });
        } else if (data.error.toString().includes("business_id")) {
          setErrors({
            business_id: "El CUIT de la sociedad ya se encuentra registrado",
          });
        }

        throw new Error(`Error: ${response.status}`); // Handle HTTP errors
      }

      const cookies = new Cookies();
      cookies.set("token", data.jwt, { path: "/", maxAge: 3600 * 24 });

      window.location.href = "/kyc";

      // Handle further actions based on response
    } catch (error) {
      console.error("Error sending data", error);
      // Handle error
    }
  };

  const getErrors = () => {
    for (var key in errors) {
      if (errors[key] !== "") {
        return errors[key];
      }
    }
  };

  return (
    <>
      <div className="center-div form-container-div">
        <form onSubmit={handleSubmit} className="form-container">
          <div>
            <h1 style={{ textAlign: "center" }}>Registrate en Plaza Cheque</h1>
          </div>

          <input
            className="input-field"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nombre"
          />
          <input
            className="input-field"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
            placeholder="Apellido"
          />
          <input
            className="input-field"
            name="country_cellphone_code"
            type="text"
            value={formData.country_cellphone_code}
            onChange={handleChange}
            placeholder="Código de país sin el '+' (54 para Argentina)"
            onWheel={(e) => e.target.blur()} // Evita que se pueda cambiar el valor con la rueda del mouse
          />
          <input
            className="input-field"
            name="cellphone"
            type="text"
            value={formData.cellphone}
            onChange={handleChange}
            placeholder="Número de celular (sin prefijo de país, ni guiones)"
            onWheel={(e) => e.target.blur()}
          />
          <input
            className="input-field"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />
          <input
            className="input-field"
            name="cuit"
            type="text" // Cambiado de "number" a "text"
            value={formData.cuit}
            onChange={handleChange}
            placeholder="CUIT"
            pattern="\d*" // Asegura que solo se puedan ingresar dígitos
            title="El CUIT debe contener solo números"
          />

          <div
            className="input-wrapper"
            style={{ width: "100%", paddingRight: "25px" }}
          >
            <input
              className="input-field"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Contraseña"
            />
            <div
              className="toggle-password-visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? (
                <svg className="eye-icon" viewBox="0 0 60 50">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <g>
                          {" "}
                          <path d="M29.008,48.308c-16.476,0-28.336-17.029-28.833-17.754c-0.248-0.36-0.231-0.841,0.039-1.184 c0.561-0.712,13.906-17.424,29.913-17.424c17.953,0,29.474,16.769,29.956,17.482c0.23,0.342,0.229,0.79-0.007,1.129 c-0.475,0.688-11.842,16.818-29.899,17.721C29.786,48.297,29.396,48.308,29.008,48.308z M2.267,30.028 c2.326,3.098,13.553,16.967,27.812,16.254c15.237-0.76,25.762-13.453,27.938-16.3c-2.175-2.912-12.811-16.035-27.889-16.035 C16.7,13.947,4.771,27.084,2.267,30.028z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <path d="M30.127,37.114c-3.852,0-6.986-3.135-6.986-6.986c0-3.851,3.134-6.985,6.986-6.985s6.986,3.135,6.986,6.985 C37.113,33.979,33.979,37.114,30.127,37.114z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <path d="M30.127,42.614c-6.885,0-12.486-5.602-12.486-12.486c0-6.883,5.602-12.485,12.486-12.485 c6.884,0,12.486,5.602,12.486,12.485C42.613,37.012,37.013,42.614,30.127,42.614z M30.127,19.641 c-5.782,0-10.486,4.704-10.486,10.486c0,5.781,4.704,10.485,10.486,10.485s10.486-4.704,10.486-10.485 C40.613,24.345,35.91,19.641,30.127,19.641z"></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              ) : (
                <svg
                  className="eye-icon"
                  viewBox="0 0 25 20"
                  style={{ height: "20px", width: "20px" }}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M20 14.8335C21.3082 13.3317 22 12 22 12C22 12 18.3636 5 12 5C11.6588 5 11.3254 5.02013 11 5.05822C10.6578 5.09828 10.3244 5.15822 10 5.23552M12 9C12.3506 9 12.6872 9.06015 13 9.17071C13.8524 9.47199 14.528 10.1476 14.8293 11C14.9398 11.3128 15 11.6494 15 12M3 3L21 21M12 15C11.6494 15 11.3128 14.9398 11 14.8293C10.1476 14.528 9.47198 13.8524 9.1707 13C9.11386 12.8392 9.07034 12.6721 9.04147 12.5M4.14701 9C3.83877 9.34451 3.56234 9.68241 3.31864 10C2.45286 11.1282 2 12 2 12C2 12 5.63636 19 12 19C12.3412 19 12.6746 18.9799 13 18.9418"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              )}
            </div>
          </div>

          <div
            className="input-wrapper"
            style={{ width: "100%", paddingRight: "25px" }}
          >
            <input
              className="input-field"
              type={showPasswordConfirmation ? "text" : "password"}
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              placeholder="Confirmar Contraseña"
            />
            <div
              className="toggle-password-visibility"
              onClick={() =>
                setShowPasswordConfirmation(!showPasswordConfirmation)
              }
            >
              {!showPasswordConfirmation ? (
                <svg className="eye-icon" viewBox="0 0 60 50">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <g>
                          {" "}
                          <path d="M29.008,48.308c-16.476,0-28.336-17.029-28.833-17.754c-0.248-0.36-0.231-0.841,0.039-1.184 c0.561-0.712,13.906-17.424,29.913-17.424c17.953,0,29.474,16.769,29.956,17.482c0.23,0.342,0.229,0.79-0.007,1.129 c-0.475,0.688-11.842,16.818-29.899,17.721C29.786,48.297,29.396,48.308,29.008,48.308z M2.267,30.028 c2.326,3.098,13.553,16.967,27.812,16.254c15.237-0.76,25.762-13.453,27.938-16.3c-2.175-2.912-12.811-16.035-27.889-16.035 C16.7,13.947,4.771,27.084,2.267,30.028z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <path d="M30.127,37.114c-3.852,0-6.986-3.135-6.986-6.986c0-3.851,3.134-6.985,6.986-6.985s6.986,3.135,6.986,6.985 C37.113,33.979,33.979,37.114,30.127,37.114z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <path d="M30.127,42.614c-6.885,0-12.486-5.602-12.486-12.486c0-6.883,5.602-12.485,12.486-12.485 c6.884,0,12.486,5.602,12.486,12.485C42.613,37.012,37.013,42.614,30.127,42.614z M30.127,19.641 c-5.782,0-10.486,4.704-10.486,10.486c0,5.781,4.704,10.485,10.486,10.485s10.486-4.704,10.486-10.485 C40.613,24.345,35.91,19.641,30.127,19.641z"></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              ) : (
                <svg
                  className="eye-icon"
                  viewBox="0 0 25 20"
                  style={{ height: "20px", width: "20px" }}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M20 14.8335C21.3082 13.3317 22 12 22 12C22 12 18.3636 5 12 5C11.6588 5 11.3254 5.02013 11 5.05822C10.6578 5.09828 10.3244 5.15822 10 5.23552M12 9C12.3506 9 12.6872 9.06015 13 9.17071C13.8524 9.47199 14.528 10.1476 14.8293 11C14.9398 11.3128 15 11.6494 15 12M3 3L21 21M12 15C11.6494 15 11.3128 14.9398 11 14.8293C10.1476 14.528 9.47198 13.8524 9.1707 13C9.11386 12.8392 9.07034 12.6721 9.04147 12.5M4.14701 9C3.83877 9.34451 3.56234 9.68241 3.31864 10C2.45286 11.1282 2 12 2 12C2 12 5.63636 19 12 19C12.3412 19 12.6746 18.9799 13 18.9418"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              )}
            </div>
          </div>
          <label className="checkbox-container">
            <input
              type="checkbox"
              name="society"
              checked={formData.society}
              onChange={handleChange}
            />
            Represento a una Sociedad
          </label>
          {formData.society && (
            <>
              <input
                className="input-field"
                name="business_name"
                value={formData.business_name}
                onChange={handleChange}
                placeholder="Razón Social"
              />
              <input
                className="input-field"
                name="business_id"
                type="text" // Cambiado de "number" a "text"
                value={formData.business_id}
                onChange={handleChange}
                placeholder="CUIT de la Sociedad"
                pattern="\d*" // Asegura que solo se puedan ingresar dígitos
                title="El CUIT de la Sociedad debe contener solo números"
              />
            </>
          )}
          {getErrors() && (
            <div className="error-message">Error: {getErrors()}</div>
          )}
          <div className="center-div">
            <button className="submit-button" type="submit">
              Registrarse
            </button>

            <p>
              Ya tenés una cuenta? <a href="/login">Ingresá!</a>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};
