import React, { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import { config } from "../config";
import { LoadingComponent } from "./loading_component";
export const RecoverPasswordForm = () => {
  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("token")) {
      window.location.href = "/profile";
    }
  }, []);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.backendURL}/recover`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) {
        // Assuming a 401 or similar error on invalid credentials
        throw new Error("Mail inválido");
      }

      alert("Se ha enviado un mail a su casilla de correo");

      //   const cookies = new Cookies();
      //   cookies.set("token", data.jwt, { path: "/", maxAge: 3600 * 24 });

      //   if (data.kyc_required) {
      //     window.location.href = "/kyc";
      //   } else {
      //     window.location.href = "/profile";
      //   }
    } catch (error) {
      console.error("Error sending data", error);
      setErrorMessage("Mail Inválido"); // Update the error message
    }
  };

  return (
    <>
      <div className="center-div form-container-div" style={{ height: "95vh" }}>
        <form onSubmit={handleSubmit} className="form-container">
          <h1 style={{ textAlign: "center" }}>Ingresá a Plaza Cheque</h1>
          <input
            className="input-field"
            type="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            placeholder="Email"
            onInvalid={(e) => {
              e.target.setCustomValidity("Email inválido");
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
            }}
          />

          {errorMessage && (
            <div className="error-message">{"Error: " + errorMessage}</div>
          )}
          <div className="center-div">
            <button className="submit-button" type="submit">
              Recuperar contraseña
            </button>
            <p>
              {" "}
              <a href="/signup">Volver al login</a>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export const CheckRecoverPassword = () => {
  // Get the token from the URL and send it to the server
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const sendToken = async () => {
      try {
        const response = await fetch(`${config.backendURL}/check-recover`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });

        const data = await response.json();

        if (!response.ok || !data.jwt) {
          throw new Error("Token inválido");
        }

        const cookies = new Cookies();
        cookies.set("token", data.jwt, { path: "/", maxAge: 3600 * 24 });

        window.location.href = "/profile";
      } catch (error) {
        console.error("Error sending data", error);
        setErrorMessage("Token inválido"); // Update the error message
      }
    };
    sendToken();
  }, [token]);

  return errorMessage ? (
    <div className="error-message">{"Error: " + errorMessage}</div>
  ) : (
    <LoadingComponent />
  );
};
