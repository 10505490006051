import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { config } from "../../config";

export const AdminTransactionsStats = () => {
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("day");

  useEffect(() => {
    fetchStats(selectedPeriod);
  }, [selectedPeriod]);

  const fetchStats = async (period) => {
    setIsLoading(true);
    setError("");

    try {
      const cookies = new Cookies();
      const token = cookies.get("token");

      const response = await fetch(
        `${config.backendURL}/admin/transactions/stats?period=${period}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (!data.stats) {
          setError("No se encontraron estadísticas de transacciones");
          setIsLoading(false);
          return;
        }
        setStats(data.stats);
      } else {
        setError("Error al obtener estadísticas de transacciones");
      }
    } catch (err) {
      setError("Ocurrió un error al obtener datos");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
  };

  if (isLoading) return <div>Cargando...</div>;
  if (error) return <div className={"error"}>{error}</div>;

  return (
    <div className={"container"}>
      <h1>Estadísticas de Transacciones</h1>
      <div>
        <select value={selectedPeriod} onChange={handlePeriodChange}>
          <option value="day">Diario</option>
          <option value="week">Semanal</option>
          <option value="month">Mensual</option>
          <option value="year">Anual</option>
        </select>
      </div>
      {stats && (
        <div>
          <p>Total Transacciones: {stats.total_transactions}</p>
          <p>Transacciones Pendientes: {stats.total_pending}</p>
          <p>Transacciones Canceladas: {stats.total_cancelled}</p>
          <p>Transacciones Completadas: {stats.total_complete}</p>
          <p>Ganancias Totales: ${stats.total_earnings}</p>
        </div>
      )}
    </div>
  );
};
