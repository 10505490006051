import React, { useState } from "react";
import Cookies from "universal-cookie";
import { config } from "../../config";
import "bootstrap/dist/css/bootstrap.min.css";

export const AdminTransactionDetails = () => {
  const [transaction, setTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const fetchTransactionData = async () => {
    setIsLoading(true);
    setError("");
    const cookies = new Cookies();
    const token = cookies.get("token");
    if (!token) {
      window.location.href = "/login";
    }

    try {
      const response = await fetch(
        `${config.backendURL}/admin/transaction?id=${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTransaction(data.transaction);
        setSelectedStatus(data.transaction.status);
      } else {
        setError("Error al obtener datos de la transacción");
        setTransaction(null);
      }
    } catch (err) {
      setError("Ocurrió un error al obtener datos de la transacción");
      setTransaction(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchTransactionData();
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleConfirmStatus = async () => {
    if (selectedStatus === "complete") {
      if (
        !window.confirm(
          "¿Está seguro de cambiar el estado de la transacción a 'Completada'? Las demás transacciones asociadas a ese cheque serán canceladas."
        )
      ) {
        return;
      }
    }
    setIsLoading(true);
    setError("");
    const cookies = new Cookies();
    const token = cookies.get("token");
    if (!token) {
      window.location.href = "/login";
    }

    try {
      const response = await fetch(`${config.backendURL}/admin/transaction`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          status: selectedStatus,
          id: transactionId,
        }),
      });

      const data = await response.json();
      if (data.error) {
        setError(data.error);
        return;
      } else {
        alert("Estado de transacción actualizado");
        fetchTransactionData(); // Refresh data after updating the status
      }
    } catch (err) {
      setError("Ocurrió un error al actualizar estado de la transacción");
      setTransaction(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center">Detalles de la Transacción</h1>
      <form className="form-inline mb-4" onSubmit={handleSearch}>
        <div className="form-group mr-2">
          <input
            type="text"
            className="form-control"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            placeholder="Ingresar ID de la Transacción"
          />
        </div>
        <button className="btn btn-primary" type="submit">
          Buscar
        </button>
      </form>
      {isLoading && <div className="text-center">Cargando...</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      {transaction && (
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <th>ID Transacción</th>
                <th>ID Cheque</th>
                <th>Email del Comprador</th>
                <th>Estado</th>
                <th>Comisión (%)</th>
                <th>Fecha de Creación</th>
                <th>Fecha de Actualización</th>
                <th>Subido Por</th>
                <th>Número de Cheque</th>
                <th>Monto del Cheque</th>
                <th>Monto Deseado</th>
                <th>Monto Transacción con Comisión</th>
                <th>Oferta Realizada? (Sí/No)</th>
                <th>Monto de la Oferta</th>
                <th>Monto a recibir por el vendedor</th>
                <th>Fecha de Expiración</th>
                <th>Código País Vendedor</th>
                <th>Teléfono Vendedor</th>
                <th>Código País Comprador</th>
                <th>Teléfono Comprador</th>
                <th>Cambiar Estado</th>
                <th>Confirmar cambio de estado</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{transaction.id}</td>
                <td>{transaction.check_id}</td>
                <td>{transaction.buyer_email}</td>
                <td>
                  {transaction.status === "pending"
                    ? "Pendiente"
                    : transaction.status}
                </td>
                <td>{transaction.fee}</td>
                <td>{new Date(transaction.created_at).toLocaleString()}</td>
                <td>
                  {transaction.updated_at === "0001-01-01T00:00:00Z"
                    ? "-"
                    : new Date(transaction.updated_at).toLocaleString()}
                </td>
                <td>{transaction.uploaded_by}</td>
                <td>{transaction.check_number}</td>
                <td>${transaction.check_amount}</td>
                <td>${transaction.desired_amount}</td>
                <td>
                  $
                  {transaction.desired_amount +
                    (transaction.desired_amount * transaction.fee) / 100}
                </td>
                <td>{transaction.offer_id ? "Sí" : "No"}</td>
                <td>
                  {transaction.offer_price
                    ? `$${transaction.offer_price.toLocaleString()}`
                    : "-"}
                </td>
                <td>
                  $
                  {transaction.offer_price
                    ? transaction.offer_price -
                      (transaction.offer_price * transaction.fee) / 100
                    : "-"}
                </td>
                <td>
                  {new Date(
                    transaction.expiration_date * 1000
                  ).toLocaleDateString("en-GB")}
                </td>
                <td>+{transaction.uploader_country_code}</td>
                <td>{transaction.uploader_cellphone}</td>
                <td>+{transaction.buyer_country_code}</td>
                <td>{transaction.buyer_cellphone}</td>
                <td>
                  <select
                    className="form-control"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="pending">Pendiente</option>
                    <option value="complete">Completada</option>
                    <option value="cancelled">Cancelada</option>
                  </select>
                </td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={handleConfirmStatus}
                  >
                    Confirmar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
